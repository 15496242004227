.privacy_title_container  {
  height: 25vh;
  width: 100%;
  max-width: 100%; max-height: 100%;
  align-self: center;

  align-items: center;
  -webkit-filter: drop-shadow(3px 3px 7px #00112b);
  filter:         drop-shadow(3px 3px 7px #00112b); 
}

.privacy_logo_container  {
  justify-content: center;
}

.privacy_logo {
  width: 100%;
  height: 100%;
}

.privacy_title  {
  display: flex;
  align-items: center;

  border-left: 2px solid white;
  height: 50%;
  margin-left: 3%;
  padding-left: 3%;
  margin-top: 1em;
  margin-bottom: 1em;
}

.privacy_privacy_title  {
  color: white;
  font-family: 'Times New Roman', Times, serif;
  font-weight: 600;
  font-size: 3em;
}

.privacy_content_container {
  width: 100%;
  justify-content: center;
}

.privacy_content  {
  max-width: 60em;
  max-height: 65vh;
  color: white;

  overflow-y: scroll;
  overflow-x: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

@media only screen and (max-width: 769px) {
  .privacy_title_container  {
    height: 15vh;
  }

  .privacy_title  {
    height: 30%;
  }

  .privacy_privacy_title  {
    font-size: 1.25em;
  }

  .privacy_logo_container  {
    flex: 1;
  }
}