.main_container {
  height: 100vh;
  background-image: url(./assets/background.jpg);
  background-size: cover;
  background-position: center center;
  background-repeat:  no-repeat;
  background-attachment: fixed;
  overflow: hidden;

  background-color: #163c7498;
  background-blend-mode: soft-light;
}

.row {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.content_container {
  min-height: 90vh;
}

a {
  color: white;
  text-decoration: none;
}

.allns_footer {
  height: 5vh;
  text-align: center;
  color: white;
  font-family: sans-serif;
  font-size: 0.75em;
  vertical-align: text-bottom;
}

.support {
  margin-left: 1em;
}

/* IE */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .main_container {
    background-color: #163c74;
    background-image: url(./assets/background-ie.jpg);
  }
}

@supports (-ms-ime-align:auto) {
  .main_container {
    background-color: #163c74;
    background-image: url(./assets/background-ie.jpg);
  }
}