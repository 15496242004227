body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.logo_container {
  width: 100%;
  height: 40vh;
}

.logo {
  max-width: 100%;
  max-height: 100%;
  align-self: flex-end;
  filter:         drop-shadow(5px 5px 7px #00112b); 
}

.smaller_logo_container {
  color: white;
  padding-top: 2em;
  justify-content: space-around;
  filter:         drop-shadow(5px 5px 7px #00112b); 
}

.download_text_container {
  background: white;
  color: black;
  margin-bottom: 1em;
  padding-top: 1em;
  border-radius: 15px;
  filter:         drop-shadow(5px 5px 7px #00112b); 
}

.ns_logo {
  max-width: 100%;
  vertical-align: -2em;
}

.nl_logo_container {
  text-align: end;
}

.nl_logo {
  max-width: 100%;
  vertical-align: -2.5em;
}

.nb_logo_container {
  text-align: start;
}

.nb_logo {
  max-width: 100%;
  vertical-align: -2.5em;
}

.badges {
  height: 20vh;
}

.badge_container {
  display: flex;
  flex: 1 1;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;

  max-width: 80%;
  min-width: 35%;
  min-height: 10.5em;
}

.store_badge {
  height: 4em;
}

.apple_badge {
  text-align: end;
}

.google_badge {
  text-align: start;
}

/* 720px is the bootstrap margin between `lg` and `md` */
@media only screen and (max-width: 760px) {
  .smaller_logo_container {
    margin-bottom: 3.5em;
    justify-content: space-around;
  }

  .ns_logo {
    max-width: 60%;
  }

  .nb_logo {
    max-width: 70%;
  }

  .apple_badge {
    text-align: start;
  }

  .store_badge {
    height: 4em;
  }
}

/* 540px is the bootstrap margin between `md` and `sm` */
@media only screen and (max-width: 540px) {
  .logo_container {
    height: 30vh;
    padding-bottom: 10%;
  }

  .logo {
    align-self: flex-end;
  }

  .store_badge {
    height: 3em;
  }
  
  .spacer {
    display: none;
  }
}

/* IE */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .logo_container {
    position: relative;
  }

  .logo {
    max-width: 100%;
    max-height: 100%;
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;
  }

  .apple_badge {
    text-align: right;
  }
}
.privacy_title_container  {
  height: 25vh;
  width: 100%;
  max-width: 100%; max-height: 100%;
  align-self: center;

  align-items: center;
  filter:         drop-shadow(3px 3px 7px #00112b); 
}

.privacy_logo_container  {
  justify-content: center;
}

.privacy_logo {
  width: 100%;
  height: 100%;
}

.privacy_title  {
  display: flex;
  align-items: center;

  border-left: 2px solid white;
  height: 50%;
  margin-left: 3%;
  padding-left: 3%;
  margin-top: 1em;
  margin-bottom: 1em;
}

.privacy_privacy_title  {
  color: white;
  font-family: 'Times New Roman', Times, serif;
  font-weight: 600;
  font-size: 3em;
}

.privacy_content_container {
  width: 100%;
  justify-content: center;
}

.privacy_content  {
  max-width: 60em;
  max-height: 65vh;
  color: white;

  overflow-y: scroll;
  overflow-x: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

@media only screen and (max-width: 769px) {
  .privacy_title_container  {
    height: 15vh;
  }

  .privacy_title  {
    height: 30%;
  }

  .privacy_privacy_title  {
    font-size: 1.25em;
  }

  .privacy_logo_container  {
    flex: 1 1;
  }
}
.support_title_container  {
  height: 25vh;
  width: 100%;
  max-width: 100%; max-height: 100%;
  align-self: center;

  align-items: center;
  filter:         drop-shadow(3px 3px 7px #00112b); 
}

.support_logo_container  {
  justify-content: center;
}

.support_logo {
  width: 100%;
  height: 100%;
}

.support_title  {
  display: flex;
  flex: 1 1;
  border-left: 2px solid white;
  height: 50%;
  margin-left: 3%;
  padding-left: 3%;
  margin-top: 1em;
  margin-bottom: 1em;
  align-items: center;
}

.support_support_title  {
  color: white;
  font-family: 'Times New Roman', Times, serif;
  font-weight: 600;
  font-size: 3em;
}

.support_content_container {
  width: 100%;
  justify-content: center;
}

.support_content  {
  max-width: 60em;
  max-height: 65vh;
  color: white;

  overflow-y: scroll;
  overflow-x: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.support_content::-webkit-scrollbar { 
  display: none;
}

::-webkit-scrollbar {
  width: 0px;  /* Remove scrollbar space */
  background: transparent;  /* Optional: just make scrollbar invisible */
}

@media only screen and (max-width: 700px) {
  .support_title_container  {
    height: 15vh;
  }

  .support_title  {
    height: 30%;
  }

  .support_support_title  {
    font-size: 1.25em;
  }

  .support_logo_container  {
    flex: 1 1;
  }
}
.main_container {
  height: 100vh;
  background-image: url(/static/media/background.a961f244.jpg);
  background-size: cover;
  background-position: center center;
  background-repeat:  no-repeat;
  background-attachment: fixed;
  overflow: hidden;

  background-color: #163c7498;
  background-blend-mode: soft-light;
}

.row {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.content_container {
  min-height: 90vh;
}

a {
  color: white;
  text-decoration: none;
}

.allns_footer {
  height: 5vh;
  text-align: center;
  color: white;
  font-family: sans-serif;
  font-size: 0.75em;
  vertical-align: text-bottom;
}

.support {
  margin-left: 1em;
}

/* IE */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .main_container {
    background-color: #163c74;
    background-image: url(/static/media/background-ie.f69afe8f.jpg);
  }
}

@supports (-ms-ime-align:auto) {
  .main_container {
    background-color: #163c74;
    background-image: url(/static/media/background-ie.f69afe8f.jpg);
  }
}
