.support_title_container  {
  height: 25vh;
  width: 100%;
  max-width: 100%; max-height: 100%;
  align-self: center;

  align-items: center;
  -webkit-filter: drop-shadow(3px 3px 7px #00112b);
  filter:         drop-shadow(3px 3px 7px #00112b); 
}

.support_logo_container  {
  justify-content: center;
}

.support_logo {
  width: 100%;
  height: 100%;
}

.support_title  {
  display: flex;
  flex: 1;
  border-left: 2px solid white;
  height: 50%;
  margin-left: 3%;
  padding-left: 3%;
  margin-top: 1em;
  margin-bottom: 1em;
  align-items: center;
}

.support_support_title  {
  color: white;
  font-family: 'Times New Roman', Times, serif;
  font-weight: 600;
  font-size: 3em;
}

.support_content_container {
  width: 100%;
  justify-content: center;
}

.support_content  {
  max-width: 60em;
  max-height: 65vh;
  color: white;

  overflow-y: scroll;
  overflow-x: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.support_content::-webkit-scrollbar { 
  display: none;
}

::-webkit-scrollbar {
  width: 0px;  /* Remove scrollbar space */
  background: transparent;  /* Optional: just make scrollbar invisible */
}

@media only screen and (max-width: 700px) {
  .support_title_container  {
    height: 15vh;
  }

  .support_title  {
    height: 30%;
  }

  .support_support_title  {
    font-size: 1.25em;
  }

  .support_logo_container  {
    flex: 1;
  }
}