.logo_container {
  width: 100%;
  height: 40vh;
}

.logo {
  max-width: 100%;
  max-height: 100%;
  align-self: flex-end;
  -webkit-filter: drop-shadow(5px 5px 7px #00112b);
  filter:         drop-shadow(5px 5px 7px #00112b); 
}

.smaller_logo_container {
  color: white;
  padding-top: 2em;
  justify-content: space-around;
  -webkit-filter: drop-shadow(5px 5px 7px #00112b);
  filter:         drop-shadow(5px 5px 7px #00112b); 
}

.download_text_container {
  background: white;
  color: black;
  margin-bottom: 1em;
  padding-top: 1em;
  border-radius: 15px;

  -webkit-filter: drop-shadow(5px 5px 7px #00112b);
  filter:         drop-shadow(5px 5px 7px #00112b); 
}

.ns_logo {
  max-width: 100%;
  vertical-align: -2em;
}

.nl_logo_container {
  text-align: end;
}

.nl_logo {
  max-width: 100%;
  vertical-align: -2.5em;
}

.nb_logo_container {
  text-align: start;
}

.nb_logo {
  max-width: 100%;
  vertical-align: -2.5em;
}

.badges {
  height: 20vh;
}

.badge_container {
  display: flex;
  flex: 1;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;

  max-width: 80%;
  min-width: 35%;
  min-height: 10.5em;
}

.store_badge {
  height: 4em;
}

.apple_badge {
  text-align: end;
}

.google_badge {
  text-align: start;
}

/* 720px is the bootstrap margin between `lg` and `md` */
@media only screen and (max-width: 760px) {
  .smaller_logo_container {
    margin-bottom: 3.5em;
    justify-content: space-around;
  }

  .ns_logo {
    max-width: 60%;
  }

  .nb_logo {
    max-width: 70%;
  }

  .apple_badge {
    text-align: start;
  }

  .store_badge {
    height: 4em;
  }
}

/* 540px is the bootstrap margin between `md` and `sm` */
@media only screen and (max-width: 540px) {
  .logo_container {
    height: 30vh;
    padding-bottom: 10%;
  }

  .logo {
    align-self: flex-end;
  }

  .store_badge {
    height: 3em;
  }
  
  .spacer {
    display: none;
  }
}

/* IE */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .logo_container {
    position: relative;
  }

  .logo {
    max-width: 100%;
    max-height: 100%;
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;
  }

  .apple_badge {
    text-align: right;
  }
}